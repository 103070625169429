var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"request-operations"},[_c('TitleBold',{attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.$t("event.request.operations.nameFirst"))+" "),_c('router-link',{attrs:{"to":{ name: 'NoteDetails', params: { id: _vm.noteId } }}},[_vm._v(" "+_vm._s(_vm.$t("event.request.operations.requestId", { id: _vm.noteId }))+" ")]),_vm._v(" "+_vm._s(_vm.$t("event.request.operations.nameSecond"))+" ")],1),_c('TitleStrip',[_vm._v(_vm._s(_vm.requestStatus(_vm.eventRequest.status)))]),_c('div',{ref:"messages",staticClass:"messages",on:{"scroll":_vm.onScrollMessages}},_vm._l((_vm.computedOperations),function(operation){return _c('div',{key:operation.id,staticClass:"message",class:{
        'self-message': operation.username === _vm.defaultName,
        'their-message': operation.username !== _vm.defaultName
      }},[(operation.usernameView)?_c('div',{staticClass:"additional-info"},[_vm._v(" "+_vm._s(operation.usernameView)+" ")]):_vm._e(),(operation.text)?_c('div',{staticClass:"message-text"},_vm._l((operation.text.split(/[\r\n]/) || []),function(text,index){return _c('p',{key:("message_" + (operation.id) + "_text_" + index)},[_vm._v(" "+_vm._s(text)+" ")])}),0):_vm._e(),(operation.operation_type)?_c('div',{staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.requestOperationType(operation.operation_type))+" ")]):_vm._e(),(operation.operation_type === _vm.changeStatusType)?_c('div',{staticClass:"badge"},[_vm._v(" Новый статус: "+_vm._s(_vm.requestStatus(operation.status_after))+" ")]):_vm._e(),(operation.created)?_c('div',{staticClass:"additional-info"},[_vm._v(" "+_vm._s(new Date(operation.created).toLocaleString(_vm.language))+" ")]):_vm._e()])}),0),_c('div',{staticClass:"new-message"},[_c('form',{staticClass:"d-flex flex-column",on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage($event)},"keypress":_vm.ctrlEnter}},[_c('base-input',{ref:"textInput",staticClass:"mb-2",attrs:{"type":"textarea","help":("Ctrl+Enter - " + (_vm.$t('event.request.operations.sendHelp')))},model:{value:(_vm.nowText),callback:function ($$v) {_vm.nowText=$$v},expression:"nowText"}}),_c('div',{staticClass:"row justify-content-end justify-content-sm-between flex-wrap g-2"},[_c('div',{staticClass:"col col-12 col-md-4 col-sm-5"},[(
              _vm.grantedOperationTypes.length && _vm.grantedOperationTypes.length > 1
            )?_c('radio-buttons-input',{attrs:{"select":"","items":_vm.grantedOperationTypes,"one-value":"","label":_vm.$t('event.request.operations.operationType')},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=_vm._n($$v)},expression:"selectedType"}}):_c('div',[_vm._v(" "+_vm._s(_vm.$t("event.request.operations.operationType"))+": "+_vm._s(_vm.requestOperationType(_vm.selectedType))+" ")]),(_vm.selectedType === _vm.changeStatusType)?_c('div',[_c('radio-buttons-input',{attrs:{"select":"","items":_vm.requestStatuses.map(function (val) { return ({
                  label: val.title,
                  value: val.id
                }); }),"one-value":"","label":_vm.$t('event.request.operations.newStatus')},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=_vm._n($$v)},expression:"selectedStatus"}})],1):_vm._e()],1),_c('div',{staticClass:"col col-12 col-sm-4 col-md-3 offset-md-5 offset-sm-3"},[_c('filled-button',{staticClass:"w-100",attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("event.request.operations.send"))+" ")])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }