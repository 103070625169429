<template>
  <div class="request-operations">
    <TitleBold center>
      {{ $t("event.request.operations.nameFirst") }}
      <router-link :to="{ name: 'NoteDetails', params: { id: noteId } }">
        {{ $t("event.request.operations.requestId", { id: noteId }) }}
      </router-link>
      {{ $t("event.request.operations.nameSecond") }}
    </TitleBold>
    <TitleStrip>{{ requestStatus(eventRequest.status) }}</TitleStrip>
    <div class="messages" ref="messages" @scroll="onScrollMessages">
      <div
        v-for="operation in computedOperations"
        :key="operation.id"
        class="message"
        :class="{
          'self-message': operation.username === defaultName,
          'their-message': operation.username !== defaultName
        }"
      >
        <div class="additional-info" v-if="operation.usernameView">
          {{ operation.usernameView }}
        </div>
        <div class="message-text" v-if="operation.text">
          <p
            v-for="(text, index) in operation.text.split(/[\r\n]/) || []"
            :key="`message_${operation.id}_text_${index}`"
          >
            {{ text }}
          </p>
        </div>
        <div class="badge" v-if="operation.operation_type">
          {{ requestOperationType(operation.operation_type) }}
        </div>
        <div class="badge" v-if="operation.operation_type === changeStatusType">
          Новый статус: {{ requestStatus(operation.status_after) }}
        </div>
        <div class="additional-info" v-if="operation.created">
          {{ new Date(operation.created).toLocaleString(language) }}
        </div>
        <!--        <div class="additional-info" v-if="!operation.read">-->
        <!--          Не прочитано-->
        <!--        </div>-->
      </div>
    </div>
    <div class="new-message">
      <form
        class="d-flex flex-column"
        @submit.prevent="sendMessage"
        @keypress="ctrlEnter"
      >
        <base-input
          v-model="nowText"
          type="textarea"
          class="mb-2"
          ref="textInput"
          :help="`Ctrl+Enter - ${$t('event.request.operations.sendHelp')}`"
        ></base-input>
        <div
          class="row justify-content-end justify-content-sm-between flex-wrap g-2"
        >
          <div class="col col-12 col-md-4 col-sm-5">
            <radio-buttons-input
              v-if="
                grantedOperationTypes.length && grantedOperationTypes.length > 1
              "
              select
              v-model.number="selectedType"
              :items="grantedOperationTypes"
              one-value
              :label="$t('event.request.operations.operationType')"
            >
            </radio-buttons-input>
            <div v-else>
              {{ $t("event.request.operations.operationType") }}:
              {{ requestOperationType(selectedType) }}
            </div>
            <div v-if="selectedType === changeStatusType">
              <radio-buttons-input
                select
                v-model.number="selectedStatus"
                :items="
                  requestStatuses.map(val => ({
                    label: val.title,
                    value: val.id
                  }))
                "
                one-value
                :label="$t('event.request.operations.newStatus')"
              >
              </radio-buttons-input>
            </div>
          </div>

          <div class="col col-12 col-sm-4 col-md-3 offset-md-5 offset-sm-3">
            <filled-button color="primary" type="submit" class="w-100">
              {{ $t("event.request.operations.send") }}
            </filled-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FilledButton from "../components/buttons/FilledButton";
import { mapGetters } from "vuex";
import RadioButtonsInput from "../components/inputs/RadioButtonsInput";
// import RegInput from "../components/inputs/RegInput";
import BaseInput from "../components/inputs/BaseInput";
import API from "../common/api/api.functions";
import TitleBold from "../components/base/TitleBold";
import wsMixin from "../mixins/wsMixin";
import TitleStrip from "../components/base/TitleStrip";
import localizeMixin from "../mixins/localizeMixin";

export default {
  name: "RequestOperations",
  components: {
    TitleStrip,
    TitleBold,
    BaseInput,
    RadioButtonsInput,
    FilledButton
  },
  mixins: [wsMixin, localizeMixin],
  data: () => ({
    changeStatusType: 18,
    selectedType: 0,
    selectedStatus: 0,
    defaultName: "Вы",
    nowText: "",
    operations: [],
    pageNext: 1,
    loadingOperations: false,
    requestOperationTypesIds: [],
    event: null,
    eventRequest: null
  }),
  computed: {
    ...mapGetters([
      "requestOperationTypes",
      "requestOperationType",
      "requestStatus",
      "requestStatuses",
      "user"
    ]),
    noteId() {
      return this.$route.params.id || null;
    },
    grantedOperationTypes() {
      return this.requestOperationTypes
        .filter(val => this.requestOperationTypesIds.includes(val.id))
        .map(val => ({
          label: val.title,
          value: val.id
        }));
    },
    computedOperations() {
      let operations = [...this.operations];
      operations.reverse();
      let newOperations = [];
      let lastUsername = undefined;
      for (const operation of operations) {
        if (!operation.user?.username) operation.username = operation.user_role;
        else if (operation.user.username === this.user.username)
          operation.username = this.defaultName;
        else if (
          operation.user?.full_name &&
          operation.user.full_name !== operation.user.username
        )
          operation.username = `${operation.user_role} - ${operation.user.full_name} [${operation.user.username}]`;
        else
          operation.username = `${operation.user_role} ${operation.user.username}`;
        if (operation.username !== lastUsername)
          operation.usernameView = operation.username;
        lastUsername = operation.username;

        newOperations.push(operation);
      }
      return newOperations;
    }
  },
  methods: {
    async sendMessage() {
      if (!this.nowText && !this.selectedType) {
        return;
      }
      // this.ws.send(
      //   JSON.stringify({
      //     message_type: "new_message",
      //     // id: (this.operations[0]?.id || 0) + 1,
      //     text: this.nowText,
      //     operation_type: this.selectedType,
      //     request: this.eventRequest.id
      //   })
      // );
      let newOperation = {
        text: this.nowText,
        operation_type: this.selectedType,
        request: this.eventRequest.id
      };
      if (this.selectedType === this.changeStatusType) {
        newOperation.status_after = this.selectedStatus;
      }
      await API.createEventRequestOperation(this.eventRequest.id, newOperation);

      // this.operations = [
      //   {
      //     id: (this.operations[0]?.id || 0) + 1,
      //     text: this.nowText,
      //     operation_type: this.selectedType
      //   },
      //   ...this.operations
      // ];
      this.selectedType = 0;
      this.nowText = "";
    },
    ctrlEnter(event) {
      if (event.ctrlKey && event.code.toLowerCase().includes("enter")) {
        this.sendMessage();
        setTimeout(() => this.$refs.textInput.$refs.input.focus(), 100);
      }
    },
    async onScrollMessages(e) {
      let messages = e.target;
      if (messages) {
        if (messages.scrollTop < 50) {
          await this.fetchOperations();
        }
      }
    },
    async fetchOperations() {
      if (!this.pageNext) return;
      if (this.loadingOperations) return;
      this.loadingOperations = true;
      let request_operations = await API.getEventRequestOperations(
        this.noteId,
        this.pageNext
      )
        .then(res => res.data)
        .catch(error => {
          this.$toast.error(JSON.stringify(error?.response?.data || {}));
          this.notFound = true;
        });
      if (request_operations) {
        this.operations = [...this.operations, ...request_operations.results];
        if (request_operations.next) {
          this.pageNext++;
        } else this.pageNext = null;
      }
      // this.loadingOperations = false;
      setTimeout(() => (this.loadingOperations = false), 100);
    }
  },
  async mounted() {
    this.$store.commit("UPDATE_TAB_TITLE", `Операции заявки №${this.noteId}`);
    this.loading++;
    this.eventRequest = await API.getEventRequestDetails(this.noteId)
      .then(res => res.data)
      .catch(error => {
        this.$toast.error(JSON.stringify(error?.response?.data || {}));
        this.notFound = true;
      });
    if (!this.eventRequest || this.notFound) {
      this.notFound = true;
      this.loading--;
      return;
    }
    if (!this.eventRequest || this.notFound) {
      this.notFound = true;
      this.loading--;
      return;
    }
    this.event = await API.getEventDetails(this.eventRequest.event)
      .then(res => res.data)
      .catch(error => {
        this.$toast.error(JSON.stringify(error?.response?.data || {}));
        this.notFound = true;
      });
    if (this.notFound || !this.event) {
      this.notFound = true;
      this.loading--;
      return;
    }
    await this.fetchOperations();
    this.$nextTick(
      () => (this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight)
    );
    if (this.eventRequest.user.username !== this.user.username)
      this.ws.send(
        JSON.stringify({
          message_type: "enter_to_operations",
          request: this.eventRequest.id
        })
      );
    this.ws.send(
      JSON.stringify({
        message_type: "granted_operation_types",
        request: this.eventRequest.id
      })
    );
    this.$store.commit("UPDATE_HEADER_TITLE", this.event.title || null);
    this.$store.commit("UPDATE_HEADER_IMAGE", this.event.big_image);
    this.loading--;
  },
  beforeDestroy() {
    if (this.eventRequest.user.username !== this.user.username)
      this.ws.send(
        JSON.stringify({
          message_type: "exit_from_operations",
          request: this.eventRequest.id
        })
      );
  },
  watch: {
    wsLastData(val) {
      let content = val.content;
      let message_type = val.message_type;
      if (
        message_type === "granted_operation_types" &&
        content.request === this.eventRequest.id
      ) {
        this.requestOperationTypesIds = content.granted_operations || [];
        if (this.requestOperationTypesIds.length)
          this.selectedType = this.requestOperationTypesIds.includes(0)
            ? 0
            : this.requestOperationTypesIds[0];
        else this.selectedType = 0;
        this.eventRequest.status = content.status;
      } else if (
        message_type === "new_message" &&
        content.request === this.eventRequest.id
      ) {
        const doScroll =
          this.$refs.messages.scrollTop + this.$refs.messages.clientHeight >=
          this.$refs.messages.scrollHeight;
        this.operations = [content, ...this.operations];
        if (doScroll)
          this.$nextTick(
            () =>
              (this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight)
          );

        this.ws.send(
          JSON.stringify({
            message_type: "read_message",
            message_id: content.id
          })
        );
        this.ws.send(
          JSON.stringify({
            message_type: "granted_operation_types",
            request: this.eventRequest.id
          })
        );
      }
    }
  }
};
</script>

<style lang="scss">
$msg-bg-opacity: 0.1;
$msg-bg-opacity-badge: 0.05;

.request-operations {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .messages {
    display: flex;
    flex-direction: column;
    //flex-grow: 1;
    margin-bottom: 1rem;
    overflow-y: scroll;
    height: 100%;
    min-height: 40vh;
    padding: 0 0.5rem;

    .badge {
      color: inherit;
    }

    .message {
      display: flex;
      flex-direction: column;
      margin: 0.25rem 0;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      & > div {
        &.additional-info {
          font-size: 0.75rem;
          margin-top: 0.25rem;
        }

        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }

        &:not(.additional-info) {
          padding: 0.25rem 0.5rem;
          position: relative;

          & > p {
            margin: inherit;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: $border-radius;
            opacity: $msg-bg-opacity;
          }
        }

        &.badge {
          &::before {
            opacity: $msg-bg-opacity-badge;
          }
        }
      }

      &.self-message {
        align-items: flex-end;
        text-align: right;

        & > div::before {
          background: theme-color("primary");
        }
      }

      &.their-message {
        align-items: flex-start;

        & > div::before {
          background: theme-color("dark");
        }
      }
    }
  }
}
</style>
